class Personalization {
    /**
     * @param {PersonalizationConfiguration} personalizationConfiguration
     */
    constructor(personalizationConfiguration) {
        this._dataAttribute = 'data-personalization-response';
        this._responseAttribute = 'one-asset-response';

        if (!personalizationConfiguration) {
            throw 'Personalization Configuration missing';
        }

        this._personalizationConfiguration = personalizationConfiguration;
        setTimeout(() => this.triggerResponse('neutral'), personalizationConfiguration.options.neutralTriggerDelay);
        this._responseSent = false;
    }

    /**
     * @param {string[]} elements ids to add attr (this._dataAttribute) = positiv to
     */
    attachDataAttributeToElements(elements) {
        for (var i in elements) {
            this.attachDataAttribute(
                document.getElementById(elements[i]),
                'positive'
            );
        }
    }

    /**
     * Set elements data attribute to value
     * @param {Element} element
     * @param {string} value
     */
    attachDataAttribute(element, value) {
        element.setAttribute(this._dataAttribute, value);
    }

    attachResponseHandlers() {
        var elementHandler = this._personalizationConfiguration.getElementHandler();
        if (elementHandler) {
            this.attachElementHandler(elementHandler.getActionType());
        }

        var unloadHandler = this._personalizationConfiguration.getUnloadHandler();
        if (unloadHandler) {
            this.attachUnloadHandler(unloadHandler.getReaction());
        }
    }

    /**
     * @param {string} eventType
     */
    attachElementHandler(eventType) {
        var triggerElements = document.querySelectorAll(
            '[' + this._dataAttribute + ']'
        );
        triggerElements.forEach((element) => {
            element.addEventListener(eventType, () =>
                this.sendResponseForElement(element)
            );
        });
    }

    /**
     * @param {string} reaction
     */
    attachUnloadHandler(reaction) {
        window.addEventListener('unload', () => this.sendResponse(reaction));
    }

    /**
     * @param {Element} element
     */
    sendResponseForElement(element) {
        var reaction = element.getAttribute(this._dataAttribute);
        this.sendResponse(reaction);
    }

    /**
     * Send response to Thunderhead by triggering event on element they listen to
     * @param {string} reaction, eg neutral
     */
    sendResponse(reaction) {
        if (!this.isResponseAllowed()) {
            return;
        }
        this.triggerResponse(reaction);

        this._responseSent = true;
    }

    triggerResponse(reaction) {

        const responseTrigger = this.getTriggerTargetForReaction(reaction);
        if (!responseTrigger) {
            console.error(`Could not find trigger target for ${reaction}`);
            return;
        }
        responseTrigger.click();
    }

    isResponseAllowed() {
        if (this._personalizationConfiguration.isMultipleResponseEnabled()) {
            return true;
        }

        return this._responseSent === false;

    }

    /**
     * Get element use to trigger response event
     * @param reaction
     * @returns {HTMLElement}
     */
    getTriggerTargetForReaction(reaction) {
        const targetParent = this._personalizationConfiguration.options.targetParent || document.body;
        return targetParent.querySelector(
            '[' + this._responseAttribute + '="' + reaction + '"]'
        );
    }
}

class PersonalizationConfiguration {
    /**
     * @param options
     * @param {HTMLElement} options.targetParent
     * @param {int} options.neutralTriggerDelay
     */
    constructor(options = {}) {
        this._personalizations = {
            element: null,
            unload: null
        };
        this._multipleResponses = false;
        this.options = {
            targetParent: document,
            neutralTriggerDelay: 100,
            ...options
        };
    }

    enableMultipleResponseTrigger() {
        this._multipleResponses = true;
    }

    isMultipleResponseEnabled() {
        return this._multipleResponses;
    }

    /**
     * @param {string} actionType eventname, eg click
     */
    addElementHandler(actionType) {
        var elementHandler = new PersonalizationType('element');
        elementHandler.setActionType(actionType);
        this._personalizations['element'] = elementHandler;
    }

    /**
     * @returns {null|PersonalizationType}
     */
    getElementHandler() {
        return this._personalizations['element'];
    }

    /**
     * @param {string} reaction, eg neutral
     */
    addUnloadHandler(reaction) {
        var unloadHandler = new PersonalizationType('unload');
        unloadHandler.setReaction(reaction);

        this._personalizations['unload'] = unloadHandler;
    }

    /**
     * @returns {null|PersonalizationType}
     */
    getUnloadHandler() {
        return this._personalizations['unload'];
    }
}

class PersonalizationType {
    constructor(type) {
        this._type = type;
    }

    /**
     * @param {string} reaction, eg neutral
     */
    setReaction(reaction) {
        this._reaction = reaction;
    }

    /**
     * @returns {string}
     */
    getReaction() {
        return this._reaction;
    }

    /**
     * @param {string} actionType, eventname, eg click
     */
    setActionType(actionType) {
        this._actionType = actionType;
    }

    getActionType() {
        return this._actionType;
    }
}

function personalizationFactory(configOpts = {}) {
    const personalizationConfiguration = new PersonalizationConfiguration(configOpts);
    personalizationConfiguration.addElementHandler('click');

    return new Personalization(
        personalizationConfiguration
    );
}